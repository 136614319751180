<template>
    <!-- CONTENT ================================================== -->
    <div>

<!-- breadcrumb --> 
<div class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb mb-0">
          <li style="cursor:no-drop" class="breadcrumb-item"><a> <i class="fas fa-building"></i> </a></li>
          <li style="cursor:pointer" class="breadcrumb-item"> <i class="fas fa-chevron-right"></i> 
            <router-link :to="`/portofolio/${detail.code_product}`" >
            <a style="color:green">{{ detail.title }}</a>
            </router-link>
          </li>
          <li style="cursor:pointer" class="breadcrumb-item"> <i class="fas fa-chevron-right"></i> 
            <router-link :to="`/portofolio/${detail.code_product}`" >
            <a style="color:green">Portofolio</a>
            </router-link>
          </li>
          <li style="cursor:no-drop" class="breadcrumb-item active"> <i class="fas fa-chevron-right"></i> <a>{{ detail.name }}</a></li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!--=================================
breadcrumb -->

<div class="wrapper">
  <!--=================================
  Property details -->
  <section class="space-pt mb-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0 order-lg-2">
          <div class="sticky-top">
            <div class="mb-4">
              <h3>{{ detail.name }}</h3>
              <span class="d-block mb-3"><i class="fas fa-ticket-alt fa-xs pr-2"></i>{{ detail.code }}</span>
            </div>
            <div class="agent-contact">
              <div class="d-flex align-items-center p-4 border border-bottom-0">
                <div class="agent-contact-avatar mr-3">
                  <img class="img-fluid rounded-circle avatar avatar-xl" :src="getImage('assets/icon/cs.png')" alt="">
                </div>
                <div class="agent-contact-name">
                  <h6>Admin Hasina</h6>
                  <a >hasina.id</a>
                  <span class="d-block"><i class="fas fa-phone-volume pr-2 mt-2"></i>+62 857-4017-7785</span>
                </div>
              </div>
              <div class="d-flex">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+${detail.name}+(+${detail.code}+)`" class="btn btn-primary col ml-0 b-radius-none">Chat Whatsapp</a>
                <!-- <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+${detail.name}+(+${detail.code}+)+%0A%0A+ini+linknya+%0A%0A+hasina.id/#/portofolio/detail/+${detail.code})`" class="btn btn-primary col ml-0 b-radius-none">Chat Whatsapp</a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 order-lg-1">
          <div class="property-detail-img popup-gallery">

            <ul class="nav nav-tabs mb-4" id="pills-tab" role="tablist">
              <li v-if="detail.foto[0]" class="nav-item">
                  <a class="nav-link active" data-toggle="pill" href="#satu" role="tab">01</a>
              </li>
              <li v-if="detail.foto[1]" class="nav-item">
                  <a class="nav-link" data-toggle="pill" href="#dua" role="tab">02</a>
              </li>
              <li v-if="detail.foto[2]" class="nav-item">
                  <a class="nav-link" data-toggle="pill" href="#tiga" role="tab">03</a>
              </li>
              <li v-if="detail.foto[3]" class="nav-item">
                  <a class="nav-link" data-toggle="pill" href="#empat" role="tab">04</a>
              </li>
              <li v-if="detail.foto[4]" class="nav-item">
                  <a class="nav-link" data-toggle="pill" href="#lima" role="tab">05</a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div v-if="detail.foto[0]" class="tab-pane fade show active" id="satu" role="tabpanel" >
                  <div class="row">
                    <div class="slide" style="float:center;text-align:center;">
                      <a class="portfolio-img"><img style="width:50%" class="img-fluid" @click="showModal(detail.code_product, detail.foto[0].image )" :src="getImage(`assets/produk/${detail.code_product}/${detail.foto[0].image}`)"  alt=""></a>
                    </div>
                  </div>
              </div>
              <div v-if="detail.foto[1]" class="tab-pane fade" id="dua" role="tabpanel">
                  <div class="row">
                    <div class="slide" style="float:center;text-align:center;">
                      <a class="portfolio-img"><img style="width:50%" class="img-fluid" @click="showModal(detail.code_product, detail.foto[1].image )" :src="getImage(`assets/produk/${detail.code_product}/${detail.foto[1].image}`)"  alt=""></a>
                    </div>
                  </div>
              </div>
              <div v-if="detail.foto[2]" class="tab-pane fade" id="tiga" role="tabpanel">
                  <div class="row">
                    <div class="slide" style="float:center;text-align:center;">
                      <a class="portfolio-img"><img style="width:50%" class="img-fluid" @click="showModal(detail.code_product, detail.foto[2].image )" :src="getImage(`assets/produk/${detail.code_product}/${detail.foto[2].image}`)"  alt=""></a>
                    </div>
                  </div>
              </div>
              <div v-if="detail.foto[3]" class="tab-pane fade" id="empat" role="tabpanel">
                  <div class="row">
                    <div class="slide" style="float:center;text-align:center;">
                      <a class="portfolio-img"><img style="width:50%" class="img-fluid" @click="showModal(detail.code_product, detail.foto[3].image )" :src="getImage(`assets/produk/${detail.code_product}/${detail.foto[3].image}`)"  alt=""></a>
                    </div>
                  </div>
              </div>
              <div v-if="detail.foto[4]" class="tab-pane fade" id="lima" role="tabpanel">
                  <div class="row">
                    <div class="slide" style="float:center;text-align:center;">
                      <a class="portfolio-img"><img style="width:50%" class="img-fluid" @click="showModal(detail.code_product, detail.foto[4].image )" :src="getImage(`assets/produk/${detail.code_product}/${detail.foto[4].image}`)"  alt=""></a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>

    <!-- Modal -->
    <div class="modal" v-if="isModalOpen">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <center>
        <img style="width:50%" class="hide-on-mobile img-fluid" :src="imageModal"  alt="">
        <img class="hide-on-desktop img-fluid" :src="imageModal"  alt="">
        </center>
      </div>
    </div>

    </div>
</template>
<style scoped>
/* Gaya untuk modal */
.modal {
  display: block;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: white;
  margin: 1% auto;
  padding: 10px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
<script>

    export default {
        name: 'Detail',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Portofolio',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id, Warna seragam dinas resmi bps'
              }
            ]
        },
        mounted() {
          this.loadProduct();
        },
        data() {
            return {
                code: this.$route.params.id ? this.$route.params.id : '',
                fetching: false,
                detail:[],
                isModalOpen: false,
                imageModal: 'https://hasina.id/assets/icon/logo.png'
            }
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            loadProduct() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "protofolio/detail", {
                    params: {
                        code: this.code,
                    }
                })
                    .then((response) => {
                        this.detail = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            showModal(part1, part2) {
              const url = 'assets/produk/'+part1+"/"+part2;
               if (url.match(this.baseUrl)) {
                    this.imageModal = url;
                } else {
                    this.imageModal = this.baseUrl + url;
                }
              this.isModalOpen = true;
            },
            closeModal() {
              this.isModalOpen = false;
            },
        }
    }
</script>
<style>
.carousel {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 400px;
}

.slide {
  flex: 0 0 100%;
  transition: transform 0.5s ease-in-out;
}
</style>